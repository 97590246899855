import './Inspiration.css';
import React from 'react';
import forInvestors from '../../../img/incubator/investors.jpg'
import PopupJoin from '../PopupJoin/PopupJoin'


const Inspiration = () => {
    return (
        <section className="incubator-inspiration inspiration-wrap forInvestors" id='labs'>
            <div className="container container_padding inspiration">
                <div className="inspiration-head-wrap">
                    <div className="inspiration-head">
                        <h2 className="inspiration-title">For Investors <br /><span className='text-colorful'>On top of the GPT wave</span></h2>
                    </div>
                    <div className="inspiration-content-mob">
                        <img className="inspiration-gif" src={forInvestors} alt="Speakers greet, waving their hands" />
                    </div>
                    <p className="inspiration-desc p1 subcolor-1">Еhe essence of GPTincubator's appeal for investors is encapsulated in the ability to be early supporters of high-potential GPT-related startups. You can identify and back promising founders from the inception of the MVP, through development, to market launch.</p>
                    <p className="inspiration-desc p1 subcolor-1">This proactive investment model is a standout feature that differentiates GPTincubator in the competitive landscape.</p>
                    <div className="inspiration-btn">
                        <PopupJoin name="Sign up" />
                    </div>
                </div>
                <div className="inspiration-content">
                    <img className="inspiration-gif" src={forInvestors} alt="Speakers greet, waving their hands" />
                </div>
            </div>
            
        </section>
    );
};

export default Inspiration;