import React from "react";
import "./Footer.css";
import logo from "../../img/logo.png";
import geekleLogo from "../../img/geekle_logo.png";
import fb from "../../img/footer/fb-white.svg";
import ln from "../../img/footer/ln-white.svg";
import twitter from "../../img/footer/tw-white.svg";
import inst from "../../img/footer/inst-white.svg";
import yb from "../../img/footer/yb-white.svg";

export default class Footer extends React.Component {
  render() {
    return (
      <div className='footer_wrap'>
        <div className='container footer'>
          <div className='footer_info'>
            <div className='footer_docs footer_item'>
              <a rel="noreferrer" className='footer_code footer_docs_item' href="https://geekle.us/code-of-conduct" target='_blank'><span>Code of Conduct</span>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.75 0.999999C12.75 0.585786 12.4142 0.249999 12 0.249999L5.25 0.249999C4.83579 0.249999 4.5 0.585785 4.5 0.999999C4.5 1.41421 4.83579 1.75 5.25 1.75H11.25V7.75C11.25 8.16421 11.5858 8.5 12 8.5C12.4142 8.5 12.75 8.16421 12.75 7.75L12.75 0.999999ZM1.53033 12.5303L12.5303 1.53033L11.4697 0.469669L0.46967 11.4697L1.53033 12.5303Z" fill="#D92499" />
                </svg>
              </a>
              <a rel="noreferrer" className='footer_legal footer_docs_item' href="https://geekle.us/legal-docs" target='_blank'><span>Legal Docs</span>
                <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.75 0.999999C12.75 0.585786 12.4142 0.249999 12 0.249999L5.25 0.249999C4.83579 0.249999 4.5 0.585785 4.5 0.999999C4.5 1.41421 4.83579 1.75 5.25 1.75H11.25V7.75C11.25 8.16421 11.5858 8.5 12 8.5C12.4142 8.5 12.75 8.16421 12.75 7.75L12.75 0.999999ZM1.53033 12.5303L12.5303 1.53033L11.4697 0.469669L0.46967 11.4697L1.53033 12.5303Z" fill="#D92499" />
                </svg></a>
            </div>
            <div className='footer_contacts footer_item'>
              <div className='footer_contacts_wrap'>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0004 12.4983L12.7304 11.8883L10.2104 14.4083C7.37158 12.9646 5.06417 10.6571 3.62039 7.81833L6.15039 5.28833L5.54039 0.0383301H0.0303908C-0.549609 10.2183 7.82039 18.5883 18.0004 18.0083V12.4983Z" fill="#D92499" />
                </svg>
                <a rel="noreferrer" className='footer_phone footer_contact' href="tel:+442045254963" target='_blank'>+442 045 254 963</a>
              </div>
              <div className='footer_contacts_wrap'>
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18.0004 12.4983L12.7304 11.8883L10.2104 14.4083C7.37158 12.9646 5.06417 10.6571 3.62039 7.81833L6.15039 5.28833L5.54039 0.0383301H0.0303908C-0.549609 10.2183 7.82039 18.5883 18.0004 18.0083V12.4983Z" fill="#D92499" />
                </svg>
                <a rel="noreferrer" className='footer_phone footer_contact' href="tel:+14156385280" target='_blank'>+141 563 852 80</a>
              </div>
              {/* <div className='footer_contacts_wrap'>
                <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 16.5283V0.52832H20V16.5283H0ZM10 9.52832L18 4.52832V2.52832L10 7.52832L2 2.52832V4.52832L10 9.52832Z" fill="#d61a46" />
                </svg>
                <a rel="noreferrer" className='footer_mail footer_contact' href="mailto:icon@geekle.us" target='_blank'>icon@geekle.us</a>
              </div> */}
            </div>
            <div className='footer_address footer_address--mob footer_item'>Geekle Corp.<br /> 910 Foulk Road, Suite 201 Wilmington, <br />DE 19803, USA</div>
          </div>
          <div className='footer_links footer_item'>
            <a rel="noreferrer" className='footer_link_geekle' href="#" target='_blank'>
              <img className='footer_geekle_logo' src={logo} alt="" />
            </a>
            <a rel="noreferrer" className='footer_link_geekle footer_link_geekle--small' href="https://geekle.us/" target='_blank'>
              <img className='footer_geekle_logo' src={geekleLogo} alt="Geekle" />
            </a>
            <div className='footer_link_block'>
              <a rel="noreferrer" className='footer_link footer_link--ln' target='_blank' href='https://www.linkedin.com/company/34674119/admin/'>
                <img className='footer_link_img' src={ln} alt="Geekle linkedin" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--fb' target='_blank' href='https://www.facebook.com/geekleclubs/'>
                <img className='footer_link_img' src={fb} alt="Geekle facebook" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--twitter' target='_blank' href='https://twitter.com/GeekleOfficial'>
                <img className='footer_link_img' src={twitter} alt="Geekle twitter" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--inst' target='_blank' href='https://www.instagram.com/geekle.us/'>
                <img className='footer_link_img' src={inst} alt="Geekle instagram" />
              </a>
              <a rel="noreferrer" className='footer_link footer_link--yb' target='_blank' href='https://www.youtube.com/channel/UCT-RTQuSWLsJXytEy7Q7n4g'>
                <img className='footer_link_img' src={yb} alt="Geekle youtube" />
              </a>
            </div>
          </div>
          <div className="chat_block">
            <p className="chat_title">Any questions left?</p>
            <p className="p1 subcolor-1 chat_undertitle">We are happy to talk</p>
            <a target='_blank' rel='noreferrer' className="chat_btn button button-colorful" href="https://tawk.to/chat/62d53950b0d10b6f3e7ce13c/1gf8k7nb7">Chat</a>
          </div>
        </div>
        <div className='footer_address footer_address--pc footer_item'>Geekle Corp. 910 Foulk Road, Suite 201 Wilmington, DE 19803, USA</div>
      </div>
    )
  }
}