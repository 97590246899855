import './Packages.css';
import React, { useState } from 'react';
import Header from '../../components/HeaderSecondary/Header.jsx';
import PackagesPC from './PackagesPC/Packages';
import PackagesMob from './PackagesMob/Packages';
import PackagesHead from './PackagesHead/PackagesHead';
import Footer from '../../components/footer/Footer.jsx';

const Packages = () => {
    const state = {
        packages_items: [
            {
                title: 'Individual one seat',
                desc: 'monthly/yearly',
                fullPrice: '33.99 / $299',
                price: '16.99 / $149',
                priceMonth: '16.99',
                priceYear: '149',
                priceFullMonth: '33.99',
                priceFullYear: '299',
                product: 'available',
                team: '1',
                subscription_time: '1m. / 1y.',
                groups_access: 'yes',
                labs_access: 'yes',
                community_access: 'yes',
                course_access: 'available',
                grad_certificates: 'yes',
                link: 'https://buy.stripe.com/28o03BcpievJcqAfZ1',
                link2: 'https://buy.stripe.com/5kA6rZ4WQbjxgGQcMQ',
            },
            {
                title: 'SMALL TEAM',
                desc: '5 seats',
                fullPrice: '1199',
                price: '599',
                product: 'available',
                team: '2-5',
                subscription_time: '1 year',
                groups_access: 'yes',
                labs_access: 'yes',
                community_access: 'yes',
                course_access: 'available',
                grad_certificates: 'yes',
                link: 'https://buy.stripe.com/eVadUr4WQ2N11LW4gl',
            },
            {
                title: 'LARGE TEAM',
                fullPrice: '1999',
                desc: '10 seats',
                price: '999',
                product: 'available',
                team: '5-10',
                subscription_time: '1 year',
                groups_access: 'yes',
                labs_access: 'yes',
                community_access: 'yes',
                course_access: 'available',
                grad_certificates: 'yes',
                colorful: true,
                link: 'https://buy.stripe.com/00gcQn0GAfzN76g3ci',
            },
            {
                title: 'ENTERPRISE',
                desc: '8 teams',
                fullPrice: '9’999',
                price: '4ʼ999',
                product: 'available',
                team: '> 80',
                subscription_time: '1 year',
                groups_access: 'yes',
                labs_access: 'yes',
                community_access: 'yes',
                course_access: 'available',
                grad_certificates: 'yes',
                enterprise: true,
                link: 'https://geekle.us/ticket/1682322252396x701957848852856800',
            },
            {
                title: 'ENTERPRISE',
                desc: '20 teams',
                fullPrice: '19’999',
                price: '9ʼ999',
                product: 'available',
                team: '> 200',
                subscription_time: '1 year',
                groups_access: 'yes',
                labs_access: 'yes',
                community_access: 'yes',
                course_access: 'available',
                grad_certificates: 'yes',
                enterprise: true,
                link: 'https://geekle.us/ticket/1682322252396x701957848852856800',
            },
        ],
    };

    return (
        <React.Fragment>
            <Header />
            <PackagesHead />
            <PackagesPC packages_items={state.packages_items} />
            <PackagesMob packages_items={state.packages_items} />
            <Footer />
        </React.Fragment>
    );
};

export default Packages;