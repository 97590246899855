import React from 'react';
import './FAQ.css';
import Accordion from './Accordion/Accordion'

const FAQ = () => {
    const state = {
        generalQuestion: [
            {
                title: 'What is GPTcoding?',
                desc: `Code for good. Explore ChatGPT. Get competitive advantage.
                Introducing GPTcoding Portal: 
                - Research Labs: Our labs specialize in discovering effective prompts for GPTcoding, a Chat-based Programming (CBP) approach. We focus on supporting Code-for-Good projects and socially significant program development areas.
                - Prompts Library: Explore our comprehensive collection of use-case-based prompts. They cover various programming concepts, algorithms, and problem domains to enhance your coding skills.
                - GPTcoding Course: Develop your skills through interactive project-based assignments. Our course leverages Chat-based Programming with ChatGPT, generating code snippets and solutions to guide you.
                
                Gain a competitive advantage with GPTcoding's cutting-edge prompts, extensive prompt library, and interactive learning experience. Start exploring prompts, mastering programming concepts, and developing projects with ChatGPT's assistance. 
                Join now and unlock the power of GPTcoding.`
            },
            {
                title: 'What does the Self Learning Course include, and what is the schedule?',
                desc: `The Self Learning Course includes one weekly task: online assignments (video lessons from the library and video assignments based on them). The subscription fee covers the courses.`
            },
            {
                title: 'How frequently will the lab streams take place?',
                desc: `We plan to hold two labs per month for each technology.`
            },
            {
                title: 'Will there be a schedule for the labs and their topics?',
                desc: `Yes, we will publish the labs' schedule and topics on the website and Discord. Typically, we schedule two monthly labs for each technology.`
            },
            // {
            //     title: `What advantages do GPTcoding's "innovativeness" and "indispensability" offer if prompts can be found on the internet?`,
            //     desc: `All proms are getting old too soon because AI learns every day and yesterday's prompt become irrelevant today. But our labs are about the collaborative research and supportive community that provides fresh use cases, best practices, and prompts to learn and shift your mindset.`
            // },
            {
                title: `Will moderators be assigned to each research group in Discord?`,
                desc: `Yes, each research group in Discord will have assigned moderators.`
            },
            {
                title: `Will the members get a certificate for participating in GPTcoding?`,
                desc: `Upon completing the Self Learning Courses, we will issue certificates, and contributors will be featured on the contributors' wall.`
            },
            {
                title: `Where can members ask questions that arise after watching the Labs and Library?`,
                desc: `The community is where members can ask questions and get answers to any queries arising after watching the Labs and Library.`
            },
            {
                title: `How much does GPTcoding cost?`,
                desc: `Generally, a monthly subscription to the library starts at $16.99. However, we offer more favorable pricing for Year or corporate packages. You can find more information and package details by following this link (insert link).`
            },
            {
                title: `How do I cancel?`,
                desc: `While we are in Beta, just send us an email with frequent with the Console request later on on your profile page, you'll be able to find button cancel my subscription. Please note that while we offer a 15 days moneyback warranty, we do not refund partially unused membership periods.
               
               If you have further questions, please don't hesitate to contact us. We're here to assist you!`
            },
        ],
    };

    return (
        <section className="faq-wrap">
            <div className="container container_padding faq">
                <h2 className='faq-title'>Frequently asked questions</h2>
                <div className='faq-accordion'>
                    {state.generalQuestion.map((item, i) => <Accordion key={`accordion-item-${i + item.title}`} title={item.title} desc={item.desc} i={i} />)}
                </div>
            </div>
        </section>
    );
};

export default FAQ;