import React from 'react';
import fb from "../../../img/footer/fb-white.svg";
import ln from "../../../img/footer/ln-white.svg";
import twitter from "../../../img/footer/tw-white.svg";
import inst from "../../../img/footer/inst-white.svg";

const Step = ({ setActiveStep, activeStep, stepsArr, item, i }) => {
    return (
        <div className={`userflow-wrap ${activeStep === i && 'active'}`}>
            <div className='userflow container'>
                <img className='userflow-img' src={item.img} alt="" />
                <h2 className='userflow-title'>{i + 1}.{item.title}</h2>
                <p className='userflow-desc p1'>{item.desc}</p>
                <div className='userflow-dots'>
                    {i !== 0 && <svg className='userflow-arr userflow-arr_prev' onClick={() => setActiveStep(i - 1)} width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 1L1 8L8 15" stroke="#ADADAD" strokeLinecap="round" />
                    </svg>}
                    {stepsArr.map((item, i) => <span key={`userflow-dot-${i}`} className={`userflow-dot ${activeStep === i && 'active'}`}></span>)}
                    {(i !== stepsArr.length - 1) && <svg className='userflow-arr userflow-arr_next' onClick={() => setActiveStep(i + 1)} width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 15L8 8L1 1" stroke="#ADADAD" strokeLinecap="round" />
                    </svg>}
                </div>
                {item.links && <div className='userflow-links'>
                    <a rel="noreferrer" className='userflow-link' target='_blank' href={item.links.ln}>
                        <img className='footer_link_img' src={ln} alt="Geekle linkedin" />
                    </a>
                    <a rel="noreferrer" className='userflow-link' target='_blank' href={item.links.fb}>
                        <img className='footer_link_img' src={fb} alt="Geekle facebook" />
                    </a>
                    <a rel="noreferrer" className='userflow-link' target='_blank' href={item.links.tw}>
                        <img className='footer_link_img' src={twitter} alt="Geekle twitter" />
                    </a>
                    <a rel="noreferrer" className='userflow-link' target='_blank' href={item.links.inst}>
                        <img className='footer_link_img' src={inst} alt="Geekle instagram" />
                    </a>
                </div>}
                {!item.link && <button onClick={() => setActiveStep(i + 1)} className={`button userflow-btn ${item.greyBtn && 'grey-btn'}`}>{item.buttonName ? item.buttonName : "Next"}</button>}
                {item.link && <a className={`button userflow-btn ${item.greyBtn && 'grey-btn'}`} href={item.link} target='_blank' rel='noreferrer'>{item.buttonName ? item.buttonName : "Next"}</a>}
            </div>
        </div>
    );
};

export default Step;