import React, { useState } from 'react';

const Packages = ({ packages_items }) => {

    const renderPackage = (item, i) => {
        return (
            <div className={`packages-item`} key={`packages-item_${i}`}>
                <p className={`packages-item-title p1`}>{item.title}</p>
                <p className={`packages-item-desc subcolor-1 p1`}>{item.desc}</p>
                {/* <button className={`packages-item_top-btn button`} onClick={() => setActivePackage(i + 1)}>{activePackage === i + 1 ? 'Selected' : 'Select'}</button> */}
                <div className='packages-item_top--btn_block'>
                    {!item.enterprise && <a href={item.link} target='_blank' rel='noreferrer' className={`packages-item_top-btn button ${item.link2 && 'mr half-wide'}`} >{item.link2 ? 'Month' : 'Buy'}</a>}
                    {item.enterprise && <a href='https://tawk.to/chat/62d53950b0d10b6f3e7ce13c/1g92dcvpu' target='_blank' rel='noreferrer' className={`packages-item_top-btn button`} >Contact Sales</a>}
                    {item.link2 && <a href={item.link2} target='_blank' rel='noreferrer' className={`packages-item_top-btn button ${item.link2 && 'half-wide'}`}>{item.link2 ? 'Year' : 'Buy'}</a>}
                </div>
                {/* <div className='packages-item-row'>
                    <p className='packages-item-row_title subcolor-1 p2'>Full price</p>
                    <p className='packages-item-row_priceFull subcolor-1 p2'>{item.priceFull}</p>
                </div> */}
                <div className={`packages-item-row packages-item-row_price-block ${item.priceFullMonth && 'two'}`}>
                    {/* <p className='packages-item-row_title p2'><b>Price</b></p> */}
                    <p className='packages-item-row_price packages-item-row_price--white p2'><b>${item.priceMonth ? item.priceMonth : item.price}</b></p>
                    {item.priceYear && <p className='packages-item-row_price packages-item-row_price--white p2'><b>${item.priceYear}</b></p>}
                </div>
                <div className={`packages-item-row packages-item-row_full-price-block ${item.priceFullMonth && 'two'}`}>
                    {/* <p className='packages-item-row_title p2'><b>Price</b></p> */}
                    <p className='packages-item-row_price packages-item-row_price--white p2'>${item.priceFullMonth ? item.priceFullMonth : item.fullPrice}</p>
                    {item.priceFullYear && <p className='packages-item-row_price packages-item-row_price--white p2'>${item.priceFullYear}</p>}
                </div>
                {/* <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Products “GPTcoding labs”</p>
                    <p className='packages-item-row_price p2'>{item.product}</p>
                </div> */}
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Seats</p>
                    <p className='packages-item-row_price p2'>{item.team}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Subscription period</p>
                    <p className='packages-item-row_price p2'>{item.subscription_time}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Access to Labs</p>
                    <p className='packages-item-row_price p2'>{item.labs_access}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Access to Library</p>
                    <p className='packages-item-row_price p2'>{item.groups_access}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Access to Self Learning Course</p>
                    <p className='packages-item-row_price p2'>{item.course_access}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Community access</p>
                    <p className='packages-item-row_price p2'>{item.community_access}</p>
                </div>
                <div className='packages-item-row'>
                    <p className='packages-item-row_title p2'>Certificates</p>
                    <p className='packages-item-row_price p2'>{item.grad_certificates}</p>
                </div>
            </div>
        );
    };

    return (
        <section className="packages-wrap packages-wrap--mob">
            <div className={`packages container`}>
                <div className='packages-top packages-row'>
                    <div className='packages-top_title-block'>
                        <div className='packages-top_title-block--inner'>
                            <h3 className='packages-top_title'>Choose your <span>membership</span></h3>
                            {/* <p className='packages-top_desc subcolor-1'>All membership plans come with a 30-day satisfaction guarantee.</p> */}
                        </div>
                        <div className='packages-top_reliable-list'>
                            <ul>
                                <li>
                                    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.5 15C1.0875 15 0.734251 14.86 0.440251 14.58C0.146251 14.3 -0.000498727 13.9638 1.27334e-06 13.5714V6.42857C1.27334e-06 6.03571 0.147001 5.69929 0.441001 5.41929C0.735001 5.13929 1.088 4.99952 1.5 5H2.25V3.57143C2.25 2.58333 2.61575 1.74095 3.34725 1.04429C4.07875 0.347619 4.963 -0.000475703 6 4.879e-07C7.0375 4.879e-07 7.922 0.348334 8.6535 1.045C9.385 1.74167 9.7505 2.58381 9.75 3.57143V5H10.5C10.9125 5 11.2657 5.14 11.5597 5.42C11.8537 5.7 12.0005 6.03619 12 6.42857V13.5714C12 13.9643 11.853 14.3007 11.559 14.5807C11.265 14.8607 10.912 15.0005 10.5 15H1.5ZM6 11.4286C6.4125 11.4286 6.76575 11.2886 7.05975 11.0086C7.35375 10.7286 7.5005 10.3924 7.5 10C7.5 9.60714 7.353 9.27071 7.059 8.99071C6.765 8.71071 6.412 8.57095 6 8.57143C5.5875 8.57143 5.23425 8.71143 4.94025 8.99143C4.64625 9.27143 4.4995 9.60762 4.5 10C4.5 10.3929 4.647 10.7293 4.941 11.0093C5.235 11.2893 5.588 11.429 6 11.4286ZM3.75 5H8.25V3.57143C8.25 2.97619 8.03125 2.47024 7.59375 2.05357C7.15625 1.63691 6.625 1.42857 6 1.42857C5.375 1.42857 4.84375 1.63691 4.40625 2.05357C3.96875 2.47024 3.75 2.97619 3.75 3.57143V5Z" fill="#D92499" />
                                    </svg>
                                    <span>No risk payment</span>
                                </li>
                                <li>
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0 9.25C0 9.71413 0.197544 10.1592 0.549175 10.4874C0.900805 10.8156 1.37772 11 1.875 11H13.125C13.6223 11 14.0992 10.8156 14.4508 10.4874C14.8025 10.1592 15 9.71413 15 9.25V4.4375H0V9.25ZM2.20982 6.875C2.20982 6.62636 2.31565 6.3879 2.50402 6.21209C2.6924 6.03627 2.94789 5.9375 3.21429 5.9375H4.82143C5.08783 5.9375 5.34332 6.03627 5.53169 6.21209C5.72007 6.3879 5.82589 6.62636 5.82589 6.875V7.5C5.82589 7.74864 5.72007 7.9871 5.53169 8.16291C5.34332 8.33873 5.08783 8.4375 4.82143 8.4375H3.21429C2.94789 8.4375 2.6924 8.33873 2.50402 8.16291C2.31565 7.9871 2.20982 7.74864 2.20982 7.5V6.875ZM13.125 0H1.875C1.37772 0 0.900805 0.184375 0.549175 0.512563C0.197544 0.840752 0 1.28587 0 1.75V2.5625H15V1.75C15 1.28587 14.8025 0.840752 14.4508 0.512563C14.0992 0.184375 13.6223 0 13.125 0Z" fill="#D92499" />
                                    </svg>
                                    <span>Processed by Stripe</span>
                                </li>
                                <li>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8 0L9.5777 2.62684L12.3251 1.26997L12.2322 4.33278L15.2771 4.67668L13.543 7.20304L15.9186 9.13852L13.0939 10.3263L14.046 13.2389L11.0276 12.711L10.2539 15.6759L8 13.6L5.74614 15.6759L4.97241 12.711L1.954 13.2389L2.90606 10.3263L0.0814285 9.13852L2.457 7.20304L0.722944 4.67668L3.7678 4.33278L3.67487 1.26997L6.4223 2.62684L8 0Z" fill="#D92499" />
                                    </svg>
                                    <span>15 days moneyback warranty</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='packages-items'>
                    {packages_items.map((item, i) => renderPackage(item, i))}
                </div>
                {/* <a target='_blank' rel='noreferrer' className='packages-btn-main button button-colorful' href={state.packages_items[activePackage - 1].link}>Continue</a> */}
            </div>
        </section>
    );
};

export default Packages;