import './Inspiration.css';
import React from 'react';
import forDevelopers from '../../../img/incubator/forDevelopers.jpg'

const Inspiration = () => {
    return (
        <section className="incubator-inspiration inspiration-wrap" id='labs'>
            <div className="container container_padding inspiration">
                <div className="inspiration-head-wrap">
                    <div className="inspiration-head">
                        <h2 className="inspiration-title">For Developers <br/><span className='text-colorful'>Unleash Your Potential with GPT</span></h2>
                    </div>
                    <div className="inspiration-content-mob">
                        <img className="inspiration-gif" src={forDevelopers} alt="Speakers greet, waving their hands" />
                    </div>
                    <p className="inspiration-desc p1 subcolor-1"><span className='strong'>Build your GPT expertise</span></p>
                    <p className="inspiration-desc p1 subcolor-1">As you collaborate on various GPT-based projects, you're not just developing software; you're building your expertise in a field that's defining the future. Here, every line of code is a step towards becoming a GPT master, opening doors to new opportunities and challenges.</p>
                    <p className="inspiration-desc p1 subcolor-1"><span className='strong'>Collaborate and Create</span></p>
                    <p className="inspiration-desc p1 subcolor-1">Join the network of enthusiasts where collaboration is the key. Work on real-world GPT projects, where your code brings great ideas to life. It's not just about coding; it's about being part of something bigger, a movement that shapes the future of technology.</p>
                    <p className="inspiration-desc p1 subcolor-1"><span className='strong'>From Developer to Co-Founder</span></p>
                    <p className="inspiration-desc p1 subcolor-1">Your journey with us could lead you from being a developer to a co-founder. The MVPs you work on have the potential to attract investor attention, giving you a unique chance to transition into a leadership role in a budding startup.</p>
                </div>
                <div className="inspiration-content">
                    <img className="inspiration-gif" src={forDevelopers} alt="Speakers greet, waving their hands" />
                </div>
            </div>
        </section>
    );
};

export default Inspiration;