import React from 'react';
import { Link } from "react-scroll";
import './main.css'
import logo from '../../../img/labs/logos/pm.png'

const Main = () => {
    return (
        <div className='lab-main_wrap'>
            <div className='container-labs container_padding lab-main'>
                <div className='lab-main_content'>
                    <h1 className='lab-main_title'>Product<br />Management<br />laboratory</h1>
                    <p className='lab-main_desc p1'>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                    <div className='lab-main_btns'>
                        <a href='https://discord.gg/Y735gcsN' target='_blank' rel='noreferrer' className='button lab-main_btn'>Join</a>
                        <Link
                            activeClass="active"
                            to='labs'
                            spy={true}
                            smooth={true}
                            offset={-94}
                        >
                            <span>Learn more</span>
                            <svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.94759 16.523C6.24394 16.8124 6.71878 16.8068 7.00817 16.5104L11.7242 11.6812C12.0136 11.3848 12.008 10.91 11.7116 10.6206C11.4153 10.3312 10.9404 10.3368 10.651 10.6332L6.45901 14.9258L2.16636 10.7338C1.87001 10.4444 1.39517 10.45 1.10577 10.7464C0.816373 11.0427 0.822005 11.5176 1.11835 11.807L5.94759 16.523ZM5.53716 0.440047L5.72165 15.9953L7.22154 15.9775L7.03706 0.422258L5.53716 0.440047Z" fill="white" />
                            </svg>

                        </Link>
                    </div>
                </div>
                <div className='lab-main_img-block'>
                    <img src={logo} alt="" />
                </div>
            </div>
        </div>
    );
};

export default Main