import React from 'react';
import './Header.css';
import Menu from './Menu/Menu.jsx';
import Logo from '../../img/logo.png';
// import geekleLogo from '../../img/geekle_logo.png';
import geekleLogo from '../../img/byGeekle.png';

const Header = (props) => {
    const [scroll, setScroll] = React.useState(0);

    const handleScroll = () => {
        setScroll(window.scrollY);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div className={scroll > 30 ? "header_wrap header_wrap--scroll" : "header_wrap"}>
            <header className="header_container">
                <div className='header_logos'>
                    <div className='header_logo'>
                        <a href='#' target='_blank' rel='noreferrer'>
                            <img src={Logo} alt="Logotype" />
                        </a>
                    </div>
                    <div className='header_logo header_logo--geekle'>
                        <a href='https://geekle.us/' target='_blank' rel='noreferrer'>
                            <img src={geekleLogo} alt="Logotype" />
                        </a>
                    </div>
                </div>
                <nav>
                    <Menu
                        speakers={props.speakers}
                        workshops={props.workshops}
                        sponsors={props.sponsors}
                        pc={props.pc}
                        team={props.team}
                        packages={props.packages}
                    />
                </nav>
            </header>
        </div>
    );
}

export default Header;