import React, { useState, useEffect, useRef } from 'react';
import './Calendar.css';
import Month from './Month'
import pic from '../../../img/calendar-pic.png'
import calendar from '../../../img/calendar.png'
import calendarMob from '../../../img/calendar-mob.png'
import PythonLogo from '../../../img/calendar/PythonLogo.webp';
import ReactLogo from '../../../img/calendar/ReactLogo.webp';
import NodeLogo from '../../../img/calendar/NodeLogo.webp';
import AngularLogo from '../../../img/calendar/AngularLogo.webp';
import JavaLogo from '../../../img/calendar/JavaLogo.webp';

const Calendar = () => {
    const [year, setYear] = useState('2023');
    const [activeTab, setActiveTab] = useState(1);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };

    const calendarItems = [
        {
            id: 1,
            date: '16',
            mounth: 'NOV',
            time: '17:00',
            logo: PythonLogo,
            title: "Architect's Lab - Medical WebApps improvements using GPT"
        },
        {
            id: 2,
            date: '23',
            mounth: 'NOV',
            time: '17:00',
            logo: ReactLogo,
            title: "Architect's Lab - e-commerce iOS Apps GPT improvements using GPT"
        },
        {
            id: 3,
            date: '30',
            mounth: 'NOV',
            time: '17:00',
            logo: NodeLogo,
            title: "Architect's Lab - Educational WebApps GPT improvements using GPT"
        },
        {
            id: 4,
            date: '7',
            mounth: 'DEC',
            time: '17:00',
            logo: AngularLogo,
            title: "Architect's Lab - Public Transport WebApps GPT improvements using GPT"
        },
        // {
        //     id: 5,
        //     date: '29',
        //     mounth: 'JUN',
        //     time: '17:00',
        //     logo: JavaLogo,
        //     title: 'Java Lab - Android App research'
        // },
    ]

    const dropdownOptions = [
        {
            year: '2023', months: [
                {
                    name: 'January',
                    days: [
                        {
                            date: '15 February',
                            title: 'C#/.NET Global Summit’23',
                            desc: 'Insights and trends of 2023 Architecture presented in 20+ Real project use',
                            image: pic,
                        },
                    ],
                },
                {
                    name: 'December',
                    days: [
                        {
                            date: '15 February',
                            title: 'C#/.NET Global Summit’23',
                            desc: 'Insights and trends of 2023 Architecture presented in 20+ Real project use',
                            image: pic,
                        },
                    ],
                },
            ]
        },
        // {
        //     year: '2024', months: [
        //         {
        //             name: 'March',
        //             days: [
        //                 {
        //                     date: '15 February',
        //                     title: 'C#/.NET Global Summit’23',
        //                     desc: 'Insights and trends of 2023 Architecture presented in 20+ Real project use',
        //                     image: pic,
        //                 },
        //             ],
        //         },
        //         {
        //             name: 'April',
        //             days: [
        //                 {
        //                     date: '15 February',
        //                     title: 'C#/.NET Global Summit’23',
        //                     desc: 'Insights and trends of 2023 Architecture presented in 20+ Real project use',
        //                     image: pic,
        //                 },
        //             ],
        //         },
        //     ],
        // },
    ];

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        };

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    const renderTabs = () => {
        const tabs = ['Challenges schedule'];

        return (
            <ul className="calendar-tabs">
                {tabs.map((tab, index) => (
                    <li
                        key={index}
                        className={activeTab === index + 1 ? 'calendar-tab active' : 'calendar-tab'}
                        onClick={() => handleTabChange(index + 1)}
                    >
                        {tab}
                    </li>
                ))}
            </ul>
        );
    };

    const renderMonths = () => {
        const selectedYear = [...dropdownOptions].find((option) => option.year === year);
        const months = selectedYear.months;
        return (
            <div className="calendar-months">
                {months.map((month, index) => (
                    <Month key={index} month={month} />
                ))}
            </div>
        );
    };

    return (
        <div className="calendar-wrap">
            <div className="calendar container">
                <h2 className="calendar-title">AGENDA</h2>
                <p className="calendar-desc p1 subcolor-1">Online meet ups for researcher teams</p>
                {/* <img className='calendar-img calendar-img--pc' src={calendar} alt="" /> */}
                {/* <img className='calendar-img calendar-img--mob' src={calendarMob} alt="" /> */}
                <div className='calendar-items'>
                    {calendarItems.map(( item ) => {
                        return (
                            <div className='calendar-item' key={`calendar-item-${item.id}`}>
                                <div className='calendar-item_times'>
                                    <div className='calendar-item_times__date'>{item.date} <span>{item.mounth}</span></div>
                                    <div className='calendar-item_times__time'>{item.time} CET</div>
                                </div>
                                <div className='calendar-item_information'>
                                    {/* <div className='calendar-item_information__logo'>
                                        <img src={item.logo} alt="technology logo" />
                                    </div> */}
                                    <div className='calendar-item_information__title'>{item.title}</div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                {/* <div className='calendar-head'>
                    {renderTabs()}
                    <div className={`calendar-dropdown ${dropdownOpen ? 'open' : ''}`} ref={dropdownRef}>
                        <div className="calendar-header" onClick={toggleDropdown}>
                            {year}
                            <div className="calendar-arrow"></div>
                        </div>
                        {dropdownOpen && (
                            <div className="calendar-content">
                                <ul>
                                    {dropdownOptions.map((option, index) => (
                                        <li key={index} onClick={() => { setYear(option.year); toggleDropdown() }}>
                                            {option.year}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                {renderMonths()} */}
                {/* <button className='button calendar-btn'>Show more</button> */}
            </div>
        </div>
    );
};

export default Calendar;
