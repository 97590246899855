import React from 'react';
import './Quote.css';

const Quote = () => {
    return (
        <section className="incubator-quote quote-wrap">
            <div className="container container_padding quote-content incubator-page">
                <p className='quote'>Your <span className='text-colorful'>Gateway </span> <span>to GPT Mastery</span></p>
                <p className='quote-desc'>"GPT Incubator bridges the gap between OpenAI’s innovative tools and real-world applications.<br/>We provide Developers with live GPT-related projects, startup Founders with potential technical co-founders, and Investors with hot ventures"</p>

            </div>
        </section>
    );
};

export default Quote;