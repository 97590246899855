import React from "react";
import './App.css';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import Main from './components/main/main.jsx';
import Packages from './components/Packages/Packages.jsx';
import Incubator from './components/Incubator/Incubator.jsx';
import ThankYou from './components/ThankYou/ThankYou.jsx';
import UserFlow from './components/UserFlow/UserFlow.jsx';
import Labs from './components/labs/mainlab.jsx';
import { Routes, Route } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop'

const App = () => {
  return (
    <React.StrictMode>
      <main>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="packages" element={<Packages />} />
          <Route path="gptsicubator" element={<Incubator />} />
          <Route path="flow" element={<UserFlow />} />
          <Route path="labs" element={<Labs />} />
          <Route path="thankyou" element={<ThankYou />} />
        </Routes>
      </main>
      <TawkMessengerReact
        propertyId="62d53950b0d10b6f3e7ce13c"
        widgetId="1g92dcvpu" />
      {/* <Countdown /> */}
    </React.StrictMode>
  );
}

export default App;