import React from 'react';
import './descr.css'
import gif from '../../../img/inspiration.gif'

const Descr = () => {
    return (
        <div className='lab-descr_wrap'>
            <div className='container-labs container_padding lab-descr'>
                <h2 className='lab-descr_title'><b>Product Management</b></h2>
                <div className='lab-desc_content_block'>
                    <div className='lab-desc_content'>
                        <p className='lab-desc_par p1'>Introducing the PM GPTcoding Laboratory, a platform for product managers to enhance their product development process using Chat GPT and AI tools. Here's how you can benefit:</p>
                        <ul>
                            <li className='p1'><span>- Join the lab:</span> Connect with a diverse community of product managers, engage in discussions, and collaborate on best practices for AI tools used in product management. Exchange ideas and foster growth.</li>
                            <li className='p1'><span>- Participate in Research Sessions:</span> Contribute to live research sessions, explore GPT chat applications, and gain valuable insights to enhance your PM journey.</li>
                            <li className='p1'><span>- Develop Your Skill of Writing Product Requirements with AI tools:</span> Immerse yourself in our real-life project, define user stories, and improve your product requirement documentation. Enhance creativity, efficiency, and accelerate your product development.</li>
                        </ul>
                        <p className='lab-desc_par p1'>Join our community and revolutionize your approach to product management. Experience exponential growth in your skills and productivity with PM GPTcoding Laboratory.</p>
                    </div>
                    <div className='lab-desc_img-block'>
                        <img src={gif} alt="" />
                    </div>
                </div>
                <a href='https://discord.gg/Y735gcsN' target='_blank' rel='noreferrer' className='button lab-btn lab-desc_btn'>Join</a>
            </div>
        </div>
    );
};

export default Descr