import React, { useState, useEffect } from 'react';
import './Form.css'
import close_popup from '../../../../img/close_popup.svg'
import { send } from 'emailjs-com';

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [linkError, setLinkError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                default:

                case 'isEmail':
                    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true);
                    break;
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty || emailError) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty, emailError])

    return {
        isEmpty,
        emailError,
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    const resetField = () => {
        setValue('');
        setDirty(false);
    }

    return {
        value,
        onChange,
        resetField,
        onBlur,
        isDirty,
        ...valid
    }
}

const Form = () => {
    const email = useInput('', { isEmpty: true, isEmail: true });
    const [dataAgreement, setDataAgreement] = useState('');
    const [privacyAgreement, setPrivacyAgreement] = useState('');
    const [letterSucces, setLetterSucces] = useState('');
    const [textErr, setTextErr] = useState('');
    const [showPopup, setShowPopup] = useState(false);

    const data = {
        email: email.value,
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_wubsia6',
            'template_kj3vqyr',
            data,
            'i6cKS4g42KKYYnphc'
        )
            .then((response) => {
                setLetterSucces(true);
            })
            .catch((err) => {
                setLetterSucces(false);
                setTextErr(err);
            });
        setShowPopup(true);
        email.resetField();
    }

    return (
        <>
            <form className='mails-form' onSubmit={onSubmit}>
                <div className='mails-form_input-block'>
                    <p className='mails-form_label p1'>Email*</p>
                    <input placeholder='Enter Email Address' onChange={e => email.onChange(e)} onBlur={e => email.onBlur(e)} className={`mails_form--input ${(email.isDirty && email.isEmpty) && 'error'}`} value={email.value} type="email" name="email" />
                    {(email.isDirty && email.isEmpty) && <p className='form-error p1'>The field cannot be empty</p>}
                    {(email.isDirty && email.emailError && !email.isEmpty) && <p className='form-error p1'>Incorrect email</p>}
                </div>
                <div className='mails-checkbox_block mails-checkbox_block-mbn'>
                    <input className='mails-checkbox' type="checkbox" id="mails" name="mails" onChange={() => setPrivacyAgreement(!privacyAgreement)} value={privacyAgreement} />
                    <label className='mails-checkbox_label subcolor-1 p1' htmlFor="mails">I have read and accept the Geekle <a href="https://geekle.us/privacy-policy" target='_blank' rel='noreferrer'>Privacy Policy</a>, <a rel='noreferrer' href="https://geekle.us/code-of-conduct" target='_blank'>Code of Conduct</a> and all <a rel="noreferrer" href="https://geekle.us/legal-docs" target='_blank'>Legal Docs</a>.</label>
                </div>
                <div className='mails-checkbox_block'>
                    <input className='mails-checkbox' type="checkbox" id="mails" name="mails" onChange={() => setDataAgreement(!dataAgreement)} value={dataAgreement} />
                    <label className='mails-checkbox_label subcolor-1 p1' htmlFor="mails">I consent to the processing of my personal data in order to get engaged by clicking “Get Started”</label>
                </div>
                <div className='mails-input-btn'>
                    <input disabled={email.isEmpty && email.emailError} className='mails-btn button' type="submit" value="Waiting list" />
                </div>
            </form>
            {
                (letterSucces === true || letterSucces === false) && showPopup === true && <div className='form_popup form_message green' onClick={() => setShowPopup(false)}>
                    <div className='form_popup-modal'>
                        <img src={close_popup} className="form_popup-close" onClick={() => setShowPopup(false)} />
                        <p className='p1'>{letterSucces === true ? 'The form has been sent.' : `The email you sent could not be delivered. Error: ${textErr}`}</p>
                    </div>
                </div>
            }
        </>
    );
};

export default Form;