import './ThankYou.css';
import React from 'react';

const ThankYou = () => {
    return (
        <section className="thankyou-wrap">
            <div className="container container_padding thankyou">
                <h2 className="thankyou-title">Thank you!</h2>
            </div>
        </section>
    );
};

export default ThankYou;