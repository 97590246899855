import './Enjoy.css';
import React from 'react';
import speakersGIF from '../../../img/inspiration.gif'
import PopupJoin from '../PopupJoin/PopupJoin'

const Enjoy = () => {
    return (
        <section className="enjoy-wrap">
            <div className="container container_padding enjoy">
                <div className="enjoy-head">
                    <h2 className="enjoy-title">Enjoy the community</h2>
                    <p className="enjoy-desc p1 subcolor-1">of GPT-coding enthusiasts</p>
                    <PopupJoin name='Join' />
                    {/* <button className='enjoy-btn button'>Enjoy</button> */}
                </div>
                <div className="enjoy-content">
                    <img className="enjoy-gif" src={speakersGIF} alt="Speakers greet, waving their hands" />
                </div>
            </div>
        </section>
    );
};

export default Enjoy;