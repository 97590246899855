import React from 'react';
// import Technologies from './Technologies/Technologies';
import Mails from './Mails/Mails.jsx';
import FAQ from './FAQ/FAQ.jsx';
import Categories from './Categories/Categories.jsx';
import Packages from './Packages/Packages.jsx';
import Inspiration from './Inspiration/Inspiration.jsx';
import Inspiration2 from './Inspiration2/Inspiration.jsx';
import Main from './main/main.jsx';
import SpeakersFrom from './SpeakersFrom/SpeakersFrom.jsx';
// import Header from '../Header/Header.jsx';
import Header from '../../components/HeaderSecondary/Header.jsx';
import Quote from './Quote/Quote.jsx';
import Calendar from './Calendar/Calendar.jsx';
// import Motivation from './Motivation/Motivation.jsx';
import Incubator from './Incubator/Incubator.jsx';
import Practices from './Practices/Practices.jsx';
import Prompts from './Prompts/Prompts.jsx';
import Enjoy from './Enjoy/Enjoy.jsx';
import Footer from '../footer/Footer.jsx';

const MainP = () => {
    return (
        <React.Fragment>
            <Header />
            <Main />
            <Quote />
            <Inspiration />
            <Practices />
            <Inspiration2 />
            {/* <Mails />
            <Enjoy />
            <Packages />
            <FAQ /> */}
            <Footer />
        </React.Fragment>
    );
};

export default MainP;