import React, { useState } from "react";
import { Link } from "react-scroll";
import hamburger from "../../../img/burger.svg";
import close from "../../../img/close-menu.svg";
import OpenMenu from "./OpenMenu/OpenMenu.jsx";
import logoGeekle from '../../../img/logo.png'
import PopupJoin from '../../main/PopupJoin/PopupJoin'
import geekleLogo from '../../../img/byGeekle.png';

const Menu = (props) => {
  const [showText, setShowText] = useState(false);
  const onClick = () => setShowText(true);
  const onClickclose = () => setShowText(false);

  return (
    <>
      <ul className='menu'>
        {/* <li className='menu_pc menu_pc--without_before'>
          <Link
            activeClass="active"
            to='labs'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Labs
          </Link>
        </li>
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='library'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Library
          </Link>
        </li> */}
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='pc'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Committee
          </Link>
        </li> */}
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='workshops'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Course
          </Link>
        </li> */}
        <li className='menu_pc'>
          <a href="/#labs">Labs</a>
        </li>
        <li className='menu_pc'>
          <a href="/gptsicubator">GPT Incubator</a>
        </li>
        <li className='menu_pc'>
          <a href="/#library">Library</a>
        </li>
        {/* <li className='menu_pc'>
          <a href="https://gptcodinglabs.com#workshops">Course</a>
        </li> */}
        {/* <li className='menu_pc'>
          <Link
            activeClass="active"
            to='sponsors'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Subscribe
          </Link>
        </li> */}
        <PopupJoin name="Sign up" />
        {/* <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='team'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Sponsors
          </Link>
        </li>
        <li className='menu_pc menu_pc--after'>
          <Link
            activeClass="active"
            to='team'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Advisors
          </Link>
        </li> */}
        <div className="mobile-menu">
          <a className="logo-mob" href='https://gptcodinglabs.com'>
            <img src={logoGeekle} alt="" />
          </a>
          <a className="logo-mob logo-mob__geekle" href='https://geekle.us/' target='_blank' rel='noreferrer'>
            <img src={geekleLogo} alt="" />
          </a>
          <div className="mobile-menu_img-block">
            {showText === false && <img alt='' src={hamburger} onClick={onClick} className="hamburgerMenu mobile-menu-img" />}
            {showText === true && <img alt='' src={close} onClick={onClickclose} className="hamburgerMenu mobile-menu-img" />}
          </div>
          <div>
            {showText ? (
              <React.Fragment>
                <OpenMenu close={onClickclose} />
                <div className="mob_blur_bcg"></div>
              </React.Fragment>
            ) : null}
          </div>
        </div>
      </ul>
    </>
  );
}

export default Menu;