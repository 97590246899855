import { Link } from "react-scroll";
import PopupJoin from '../../../main/PopupJoin/PopupJoin'

const OpenMenu = (props) => {
  return (
    <>
      <div className="openMenu">
        <ul>
          <li>
            <a href="/#labs">Labs</a>
          </li>
          <li>
          <a href="/gptsicubator">GPT Incubator</a>
          </li>
          <li>
            <a href="/#library">Library</a>
          </li>
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='labs'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Labs
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Workshops
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='library'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Library
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='pc'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Committee
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Course
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='sponsors'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Subscribe
            </Link>
          </li> */}
          <PopupJoin name="Join" />
          {/* <li className='menu_pc--after'>
            <Link
              onClick={props.close}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Sponsors
            </Link>
          </li>
          <li className='menu_pc--after'>
            <Link
              onClick={props.close}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Advisors
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
};
export default OpenMenu;
