import React from 'react';
// import Technologies from './Technologies/Technologies';
import Mails from './Mails/Mails';
import FAQ from './FAQ/FAQ';
import Categories from './Categories/Categories';
import Packages from './Packages/Packages';
import Inspiration from './Inspiration/Inspiration';
import Main from './main/main.jsx';
import SpeakersFrom from './SpeakersFrom/SpeakersFrom.jsx';
import Header from '../../components/Header/Header.jsx';
import Quote from './Quote/Quote.jsx';
import Calendar from './Calendar/Calendar.jsx';
// import Motivation from './Motivation/Motivation.jsx';
import Incubator from './Incubator/Incubator.jsx';
import Practices from './Practices/Practices.jsx';
import Prompts from './Prompts/Prompts.jsx';
import Enjoy from './Enjoy/Enjoy.jsx';
import Footer from '../../components/footer/Footer.jsx';

const MainP = () => {
    return (
        <React.Fragment>
            <Header />
            <Main />
            <Quote />
            <Categories />
            <SpeakersFrom />
            <Inspiration />
            <Calendar />
            {/* <Motivation /> */}
            {/* <Technologies /> */}
            <Incubator />
            <Practices />
            {/* <Prompts /> */}
            <Mails />
            <Enjoy />
            <Packages />
            <FAQ />
            <Footer />
        </React.Fragment>
    );
};

export default MainP;