import './Incubator.css';
import React from 'react';
import img1 from '../../../img/incubator/tech cofounders.jpg'
import img2 from '../../../img/incubator/investors.jpg'
import img3 from '../../../img/incubator/networking.jpg'
import img4 from '../../../img/incubator/inspiration.jpg'
import img5 from '../../../img/incubator/frameworks.jpg'
import PopupJoin from '../PopupJoin/PopupJoin'

const Incubator = () => {
    const state = {
        Incubator_items: [
            {
                img: img1,
                title: 'Tech cofounders',
            },
            {
                img: img2,
                title: 'Investors',
            },
            {
                img: img3,
                title: 'Networking',
            },
            {
                img: img4,
                title: 'Inspiration',
            },
            {
                img: img5,
                title: 'Frameworks',
            },
        ],
    };

    const renderIncubators_item = (item, i) => {
        return (
            <div className="incubator-card" key={`incubator-card_${i}`}>
                <img className="incubator-card_img" src={item.img} alt="" />
                <div className="incubator-card_content">
                    <h3 className="incubator-card_title">{item.title}</h3>
                    {/* <p className="incubator-card_date p1 subcolor-1">{item.desc}</p> */}
                </div>
            </div>
        );
    };

    return (
        <section className="incubator-wrap" id='incubator'>
            <div className="container container_padding incubator">
                <div className='incubator-motivation_block'>
                    <div className='incubator-motivation_content'>
                        <h2 className='incubator-motivation_title'>GPT <span>INCUBATOR</span></h2>
                        <p className='incubator-motivation_desc p1 subcolor-1'>Pitch Your Idea, Team-Up with Tech Co-founders, Secure Funding, and Select Your Mentors</p>
                    </div>
                </div>
                <div className="incubator-content">
                    {state.Incubator_items.map((item, i) => renderIncubators_item(item, i))}
                </div>
                <div className="incubator-btn_wrap">
                    {/* <PopupJoin name="Join now" /> */}
                    <a className="button incubator-link" href="/gptsicubator">
                        <span>Learn more</span>
                    </a>
                </div>
            </div>
        </section>
    );
};

export default Incubator;