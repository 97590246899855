import React from 'react';
import './SpeakersFrom.css';
import logo1 from '../../../img/sf/1.svg'
import logo2 from '../../../img/sf/2.svg'
import logo3 from '../../../img/sf/3.svg'
import logo4 from '../../../img/sf/4.svg'
import logo5 from '../../../img/sf/5.svg'
import logo6 from '../../../img/sf/6.svg'
import logo7 from '../../../img/sf/7.svg'
import logo8 from '../../../img/sf/8.svg'
import logo9 from '../../../img/sf/9.svg'
import logo10 from '../../../img/sf/10.svg'
import logo11 from '../../../img/sf/11.svg'
import logo12 from '../../../img/sf/12.svg'
import LogosSlider from './LogosSlider/LogosSlider';

const SpeakersFrom = () => {
    const state = {
        sf_items: [
            {
                img: logo3,
                alt: 'Booking',
            },
            {
                img: logo6,
                alt: 'Google',
            },
            {
                img: logo4,
                alt: 'Ebay',
            },
            {
                img: logo2,
                alt: 'Nasa',
            },
            {
                img: logo11,
                alt: 'Accenture',
            },
            {
                img: logo5,
                alt: 'Facebook',
            },
            {
                img: logo8,
                alt: 'AWS',
            },
            {
                img: logo7,
                alt: 'Calendly',
            },
            {
                img: logo12,
                alt: 'JobCloud',
            },
            {
                img: logo9,
                alt: 'Microsoft',
            },
            {
                img: logo1,
                alt: 'Adobe',
            },
            {
                img: logo10,
                alt: 'Samsung',
            },
        ],
        sf_items1: [
            {
                img: logo3,
                alt: 'Booking',
            },
            {
                img: logo6,
                alt: 'Google',
            },
            {
                img: logo4,
                alt: 'Ebay',
            },
            {
                img: logo2,
                alt: 'Nasa',
            },
            {
                img: logo11,
                alt: 'Accenture',
            },
            {
                img: logo3,
                alt: 'Booking',
            },
            {
                img: logo6,
                alt: 'Google',
            },
            {
                img: logo4,
                alt: 'Ebay',
            },
            {
                img: logo2,
                alt: 'Nasa',
            },
            {
                img: logo11,
                alt: 'Accenture',
            },
        ],
        sf_items2: [
            {
                img: logo5,
                alt: 'Facebook',
            },
            {
                img: logo8,
                alt: 'AWS',
            },
            {
                img: logo7,
                alt: 'Calendly',
            },
            {
                img: logo12,
                alt: 'JobCloud',
            },
            {
                img: logo9,
                alt: 'Microsoft',
            },
            {
                img: logo1,
                alt: 'Adobe',
            },
            {
                img: logo10,
                alt: 'Samsung',
            },
            {
                img: logo5,
                alt: 'Facebook',
            },
            {
                img: logo8,
                alt: 'AWS',
            },
            {
                img: logo7,
                alt: 'Calendly',
            },
            {
                img: logo12,
                alt: 'JobCloud',
            },
            {
                img: logo9,
                alt: 'Microsoft',
            },
            {
                img: logo1,
                alt: 'Adobe',
            },
            {
                img: logo10,
                alt: 'Samsung',
            },
        ]
    };

    const renderSF_item = (item, i) => {
        return (
            <img className='sf-logo' src={item.img} alt={item.alt} key={`sf-item_${i}`} />
        );
    };

    return (
        <section className="sf-wrap">
            <div className="container container_padding sf">
                <div className="sf-head">
                    <h2>Recognized by developers from</h2>
                </div>
                <div className='sf-content'>
                    {state.sf_items.map((item, i) => renderSF_item(item, i))}
                </div>
                <LogosSlider item={state.sf_items1} scrollSpeed={0.5} />
                <LogosSlider item={state.sf_items2} scrollSpeed={0.5} />
            </div>
        </section>
    );
};

export default SpeakersFrom;