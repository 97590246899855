import "./OpenMenu.css";
import { Link } from "react-scroll";
import PopupJoin from '../../../main/PopupJoin/PopupJoin'

const OpenMenu = (props) => {
  return (
    <>
      <div className="openMenu">
        <ul>
          <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='labs'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Labs
            </Link>
          </li>
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Workshops
            </Link>
          </li> */}
          <li>
          <Link
            activeClass="active"
            to='incubator'
            spy={true}
            smooth={true}
            offset={-94}
          >
            GPT Incubator
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            to='library'
            spy={true}
            smooth={true}
            offset={-94}
          >
            Library
          </Link>
        </li>
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='pc'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Committee
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='workshops'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Course
            </Link>
          </li> */}
          {/* <li>
            <Link
              onClick={props.close}
              activeClass="active"
              to='sponsors'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Subscribe
            </Link>
          </li> */}
          <PopupJoin name="Join" />
          {/* <li className='menu_pc--after'>
            <Link
              onClick={props.close}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Sponsors
            </Link>
          </li>
          <li className='menu_pc--after'>
            <Link
              onClick={props.close}
              activeClass="active"
              to='team'
              spy={true}
              smooth={true}
              offset={-94}
            >
              Advisors
            </Link>
          </li> */}
        </ul>
      </div>
    </>
  );
};
export default OpenMenu;
