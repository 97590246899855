import React, { useEffect, useRef } from 'react';

const ScrollingImages = ({ item, scrollSpeed }) => {
    const containerRef = useRef(null);
    const clonedItem = [...item];

    useEffect(() => {
        const container = containerRef.current;
        let scrollLeft = 0;

        const scroll = () => {
            scrollLeft += scrollSpeed;
            container.scrollLeft = scrollLeft;

            if (scrollLeft >= container.scrollWidth - container.clientWidth) {
                scrollLeft = 0;
                container.scrollLeft = scrollLeft;
            }

            requestAnimationFrame(scroll);
        };

        let animationFrameId = requestAnimationFrame(scroll);

        return () => {
            cancelAnimationFrame(animationFrameId);
        };
    }, [clonedItem, scrollSpeed]);

    return (
        <div className="scrolling-images-container" ref={containerRef}>
            {clonedItem.map((item_child, index) => (
                <div key={`${index} scrolling-image-wrapper`} className="scrolling-image-wrapper">
                    <img src={item_child.img} alt={item_child.alt} />
                </div>
            ))}
        </div>
    );
};

export default ScrollingImages;