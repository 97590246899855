import React from 'react'
import creative from '../../../img/main/creative.png'
import creativeMob from '../../../img/main/creative-mob.png'
import './main.css'
import PopupJoin from '../PopupJoin/PopupJoin'

export default function Main() {
    return (
        <section className='main-wrap'>
            <div className='main container'>
                <div className='main-content main-item'>
                    <h1 className='main-title text-colorful'>GPT Incubator</h1>
                    <h2 className='main-subtitle'>Where Developers Startups and Investors meet<br/><br/></h2>
                    <p className='main-desc subcolor-1 p1'>We help to build GPT-related MVPs, showcase them on the market, and get funding.</p>
                    {/* <button className='button main-btn'>Sign up</button> */}
                    <PopupJoin name="Sign up" />
                </div>
                <div className='main-img_block main-item'>
                    <img className='main-img main-img_pc' src={creative} alt="" />
                    <img className='main-img main-img_mob' src={creativeMob} alt="" />
                </div>
            </div>
        </section>
    )
}