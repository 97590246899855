import React, { useState, useEffect } from "react";
import './PopupJoin.css';
import closeImg from '../../../img/close_popup.svg'
import { Link } from "react-scroll";
import Popup from 'reactjs-popup';
import { send } from 'emailjs-com';

const useValidation = (value, validations) => {
    const [isEmpty, setEmpty] = useState(true);
    const [emailError, setEmailError] = useState(true);
    const [inputValid, setInputValid] = useState(false);

    useEffect(() => {
        for (const validation in validations) {
            switch (validation) {
                case 'isEmpty':
                    value ? setEmpty(false) : setEmpty(true);
                    break;
                case 'isEmail':
                    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
                    re.test(String(value).toLowerCase()) ? setEmailError(false) : setEmailError(true);
                    break;
                default:
            }
        }
    }, [value, validations]);

    useEffect(() => {
        if (isEmpty || emailError) {
            setInputValid(false);
        } else {
            setInputValid(true);
        }

    }, [isEmpty, emailError])

    return {
        isEmpty,
        emailError,
    }
}

const useInput = (initialValue, validations) => {
    const [value, setValue] = useState(initialValue);
    const [isDirty, setDirty] = useState(false);
    const valid = useValidation(value, validations);

    const onChange = (e) => {
        setValue(e.target.value);
    }

    const onBlur = (e) => {
        setDirty(true)
    }

    const resetField = () => {
        setValue('');
        setDirty(false);
    }

    return {
        value,
        onChange,
        onBlur,
        resetField,
        isDirty,
        ...valid
    }
}
export default function Packages(props) {
    const pageLink = window.location.href.toString();
    const indexUtm = pageLink.indexOf('?');
    const name = useInput('', { isEmpty: true });
    const surname = useInput('', { isEmpty: true });
    const company = useInput('', { isEmpty: true });
    const email = useInput('', { isEmpty: true, isEmail: true });
    const [dataPrivacy, setDataPrivacy] = useState(false);

    const data = {
        name: name.value,
        surname: surname.value,
        company: company.value,
        email: email.value,
    }

    const onSubmit = (evt) => {
        evt.preventDefault();
        send(
            'service_wubsia6',
            'template_kj3vqyr',
            data,
            'i6cKS4g42KKYYnphc'
        )
            .catch((err) => {
                console.log(err)
            });
        name.resetField();
        surname.resetField();
        company.resetField();
        email.resetField();
        window.open('https://gptcodinglabs.com/packages');
    }

    return (
        <Popup
            trigger={<button className='popup_btn--join button'>{props.name}</button>}
            modal
            nested
            onClose={() => { document.querySelector('html').style.setProperty('overflow', 'auto') }}
            onOpen={() => { document.querySelector('html').style.setProperty('overflow', 'hidden'); document.querySelector('html').style.setProperty('position', 'relative'); document.querySelector('html').style.setProperty('height', '100%') }}
        >
            {close => (
                <>
                    <div className="modal modal--join">
                        <img src={closeImg} className="close" onClick={close} />
                        <h2 className="packages-title">Welcome to the GPTcoding Labs</h2>
                        <p className="packages-sales p1">*Sales not starting yet, join waiting list*</p>
                        <p className="packages-desc p1 subcolor-1">Sign up or login to continue</p>
                        <form className='packages-form'>
                            <div className='packages-form_block'>
                                <input placeholder='First name*' onChange={e => name.onChange(e)} onBlur={e => name.onBlur(e)} className={`packages-form_input ${(name.isDirty && name.isEmpty) && 'error'}`} value={name.value} type="text" name="from_name" />
                                {(name.isDirty && name.isEmpty) && <p className='form-error p2'>The field cannot be empty</p>}
                                <input placeholder='Last name*' onChange={e => surname.onChange(e)} onBlur={e => surname.onBlur(e)} className={`packages-form_input ${(surname.isDirty && surname.isEmpty) && 'error'}`} value={surname.value} type="text" name="surname" />
                                {(surname.isDirty && surname.isEmpty) && <p className='form-error p2'>The field cannot be empty</p>}
                                <input placeholder='Company*' onChange={e => company.onChange(e)} onBlur={e => company.onBlur(e)} className={`packages-form_input ${(company.isDirty && company.isEmpty) && 'error'}`} value={company.value} type="text" name="company" />
                                {(company.isDirty && company.isEmpty) && <p className='form-error p2'>The field cannot be empty</p>}
                                <input placeholder='Your e-mail*' onChange={e => email.onChange(e)} onBlur={e => email.onBlur(e)} className={`packages-form_input ${(email.isDirty && email.isEmpty) || (email.isDirty && email.emailError) && 'error'}`} value={email.value} type="email" name="email" />
                                {(email.isDirty && email.isEmpty) && <p className='form-error p2'>The field cannot be empty</p>}
                                {(email.isDirty && email.emailError) && <p className='form-error p2'>Incorrect email</p>}
                            </div>
                        </form>
                        <div className='mails-checkbox_block'>
                            <input className='mails-checkbox' type="checkbox" id="mails" name="mails" onChange={() => setDataPrivacy(!dataPrivacy)} value={dataPrivacy} />
                            <label className='mails-checkbox_label packages-checkbox_label subcolor-1 p1' htmlFor="mails">I have read and accept the Geekle <a href="https://geekle.us/privacy-policy" target='_blank' rel='noreferrer'>Privacy Policy</a>, <a rel='noreferrer' href="https://geekle.us/code-of-conduct" target='_blank'>Code of Conduct</a> and all <a rel="noreferrer" href="https://geekle.us/legal-docs" target='_blank'>Legal Docs</a>.</label>
                        </div>
                        <div className="packages-btn_block">
                            <input disabled={dataPrivacy !== true} onClick={(e) => { onSubmit(e); close() }} className='packages-btn button' type="submit" value="Submit" />
                        </div>
                    </div>
                </>
            )}
        </Popup>
    )
};