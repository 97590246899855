import './UserFlow.css';
import React from 'react';
import Header from '../../components/HeaderSecondary/Header.jsx';
import Step from './Step/Step';
import imgStep1 from '../../img/userflow/1.png'
import imgStep2 from '../../img/userflow/2.png'
import imgStep3 from '../../img/userflow/3.png'
import imgStep4 from '../../img/userflow/4.png'
import userflowDesign from '../../img/packages-design.png'

const UserFlow = () => {
    const [activeStep, setActiveStep] = React.useState(0);
    const state = {
        steps: [
            {
                img: imgStep1,
                title: 'We are in Beta',
                desc: 'As we are currently in beta, we are offering a special discounted price, so we are asking for your patience to any features that are not ready yet.',
            },
            {
                img: imgStep2,
                title: 'Check your email',
                desc: 'Find the email with confirmation and instructions. Also keep an eye on your inbox for instructions and updates.',
            },
            {
                img: imgStep3,
                title: 'Share and Promote',
                desc: 'Help us spread the word about our platform by sharing your experience and promoting it within your network. Your support is invaluable in growing our community and making it even more vibrant.',
                buttonName: 'Next',
                links: {
                    ln: 'https://www.linkedin.com/company/34674119/admin/',
                    fb: 'https://www.facebook.com/geekleclubs/',
                    tw: 'https://twitter.com/GeekleOfficial',
                    inst: 'https://www.instagram.com/geekle.us/',
                },
                greyBtn: true,
            },
            {
                img: imgStep4,
                title: 'Join Discord and Await Access',
                desc: 'Join our Discord community and wait for your access to be granted. We will notify you as soon as your access is ready, providing you with the necessary information to dive into the platform via email.',
                buttonName: 'Discord',
                link: 'https://discord.gg/Y735gcsN',
            },
        ],
    };

    const renderSteps = state.steps.map((item, i) => {
        return <Step setActiveStep={setActiveStep} activeStep={activeStep} stepsArr={state.steps} item={item} i={i} key={`userflow-steps-${i}`} />
    })

    return (
        <div className='userflow-wrapper'>
            <img className='userflow-design' src={userflowDesign} alt="" />
            <Header />
            {renderSteps}
        </div>
    );
};

export default UserFlow;