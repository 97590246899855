import React from 'react';
import './discord.css'
import discord from '../../../img/labs/discord/pm.png'
import mailsDesign from '../../../img/mails-design.png'

const Discord = () => {
    return (
        <div className='lab-discord_out'>
            <img className='lab-discord_creative' src={discord} alt="" />
            <div className='lab-discord_wrap'>
                <div className='container-labs container_padding lab-discord'>
                    <h2 className='lab-discord_title'>Join our <span>Product Management Lab</span> Discord Chanel</h2>
                    <a href='https://discord.gg/Y735gcsN' target='_blank' rel='noreferrer' className='button lab-btn lab-desc_btn'>Join</a>
                </div>
                <img className="lab-discord_design" src={mailsDesign} alt="" />
            </div>
        </div>
    );
};

export default Discord