import React from 'react';
import './Mails.css';
import Form from './Form/Form'
import mailsDesign from '../../../img/mails-design.png'
import joinBeta from '../../../img/join-beta.svg'

const Mails = () => {
    return (
        <section className="mails-wrap">
            <div className="container container_padding mails">
                <h2 className="mails-title">GPTLearning <span>Workshops</span></h2>
                <p className='mails-desc p1 subcolor-1'>Learn lessons based on the latest discoverings of the Labs, stored in the Library.</p>
                <Form />
                <img className="mails-join-beta" src={joinBeta} alt="" />
            </div>
            <img className="mails-design" src={mailsDesign} alt="" />
        </section>
    );
};

export default Mails;