import React from 'react';
import Main from './main/main.jsx';
import Descr from './descr/descr.jsx';
import Discord from './discord/discord.jsx';
import Calendar from './Calendar/Calendar.jsx';
import Footer from '../footer/Footer.jsx';
import Header from '../HeaderSecondary/Header.jsx';

const MainLab = () => {
    return (
        <div className='lab'>
            <Header />
            <Main />
            <Descr />
            <Discord />
            <Calendar />
            <Footer />
        </div>
    );
};

export default MainLab;