import React, { useState } from 'react';
import './Accordion.css';

const Accordion = (props) => {
    const [hideDesc, setHideDesc] = useState(true);

    return (
        <button onClick={() => setHideDesc(!hideDesc)} className="accordion-item">
            <div className="accordion-item_head">
                <p className='accordion-title p1'>{props.title}</p>
                {hideDesc && <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" aria-hidden="true" role="img" className="mc-icon mc-icon--md mc-ml-5 mc-accordion__icon"><path fillRule="evenodd" clipRule="evenodd" d="M2.325 7.322a1.12 1.12 0 0 1 1.572 0L12 15.344l8.103-8.022a1.12 1.12 0 0 1 1.572 0c.433.43.433 1.126 0 1.556l-8.89 8.8a1.12 1.12 0 0 1-1.57 0l-8.89-8.8a1.092 1.092 0 0 1 0-1.556Z" fill="#fff"></path></svg>}
                {!hideDesc && <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M2.32524 17.1781C2.53477 17.3845 2.8171 17.5002 3.11124 17.5002C3.40537 17.5002 3.6877 17.3845 3.89724 17.1781L12.0002 9.15612L20.1032 17.1781C20.3128 17.3845 20.5951 17.5002 20.8892 17.5002C21.1834 17.5002 21.4657 17.3845 21.6752 17.1781C22.1082 16.7481 22.1082 16.0521 21.6752 15.6221L12.7852 6.82212C12.5758 6.6163 12.2939 6.50098 12.0002 6.50098C11.7066 6.50098 11.4247 6.6163 11.2152 6.82212L2.32524 15.6221C2.22207 15.7237 2.14015 15.8448 2.08423 15.9784C2.02831 16.112 1.99951 16.2553 1.99951 16.4001C1.99951 16.5449 2.02831 16.6883 2.08423 16.8218C2.14015 16.9554 2.22207 17.0765 2.32524 17.1781Z" fill="white" />
                </svg>}
            </div>
            {!hideDesc && <p className='accordion-desc p1'>{props.desc}</p>}
        </button>
    );
};

export default Accordion;