import './Inspiration.css';
import React from 'react';
import speakersGIF from '../../../img/inspiration.gif'

const Inspiration = () => {
    return (
        <section className="inspiration-wrap" id='labs'>
            <div className="container container_padding inspiration">
                <div className="inspiration-head-wrap">
                    <div className="inspiration-head">
                        <h2 className="inspiration-title">Introducting <span className='text-colorful'>GPTcoding labs</span></h2>
                    </div>
                    <div className="inspiration-content-mob">
                        <img className="inspiration-gif" src={speakersGIF} alt="Speakers greet, waving their hands" />
                    </div>
                    <p className="inspiration-desc p1 subcolor-1">A community of EARLY ADOPTERS exploring the advantages of AI in Software Development. Their aim is to fill up the G.Library with freshest use cases and best practices.</p>
                </div>
                <div className="inspiration-content">
                    <img className="inspiration-gif" src={speakersGIF} alt="Speakers greet, waving their hands" />
                </div>
            </div>
        </section>
    );
};

export default Inspiration;