import './Packages.css';
import React from 'react';
import packagesImg from '../../../img/packages-img.png'
import PopupJoin from '../PopupJoin/PopupJoin'

const Packages = () => {
    return (
        <section className="packages-wrap packages-wrap_main">
            <div className="container container_padding packages">
                <div className="packages-card">
                    <h2 className="packages-title">Start your journey today</h2>
                    <p className='packages-desc p1 subcolor-1'>Choose from 3 plans.<br />
                        Starting at <span>16.99 per month</span>, billed annually.</p>
                    {/* <button className='button packages-btn'>Sign up</button> */}
                    <PopupJoin name='Sign up' />
                </div>
                <div className="packages-card-wrap">
                    <img src={packagesImg} alt="" />
                    <div className="packages-card">
                        {/* <p className='packages-undertitle p1 subcolor-1'>MasterClass at Work</p> */}
                        <h2 className="packages-title">Group Discounts and Enterprise offers</h2>
                        <p className='packages-desc p1 subcolor-1'>Join leading organizations in upskilling their teams with GPTcoding from Geekle.</p>
                        {/* <button className='button packages-btn'>Contact sales</button> */}
                        <PopupJoin name='Contact sales' />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Packages;