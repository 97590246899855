import React from 'react'
import creative from '../../../img/main/creative.png'
import creativeMob from '../../../img/main/creative-mob.png'
import './main.css'
import PopupJoin from '../PopupJoin/PopupJoin'

export default function Main() {
    return (
        <section className='main-wrap'>
            <div className='main container'>
                <div className='main-content main-item'>
                    <h1 className='main-title'>Learn <span className='text-colorful'>GPTcoding</span> <br />today to be <br />competitive tomorrow</h1>
                    <p className='main-desc subcolor-1 p1'>Get access to GPT Discovery Streams, Best Practice Library, and Learning courses to prepare yourself for tomorrow</p>
                    {/* <button className='button main-btn'>Sign up</button> */}
                    <PopupJoin name="Sign up" />
                </div>
                <div className='main-img_block main-item'>
                    <img className='main-img main-img_pc' src={creative} alt="" />
                    <img className='main-img main-img_mob' src={creativeMob} alt="" />
                </div>
            </div>
        </section>
    )
}