import './Categories.css';
import React from 'react';
import PopupJoin from '../PopupJoin/PopupJoin'

const Categories = () => {
    const state = {
        categories_items: [
            {
                title: 'React',
            },
            {
                title: 'Angular',
            },
            {
                title: 'Vue',
            },
            {
                title: 'Typescript',
            },
            {
                title: 'Python',
            },
            {
                title: 'Java',
            },
            {
                title: 'Go',
            },
            {
                title: '#C/.NET',
            },
            {
                title: 'Testing',
            },
            {
                title: 'Architecture',
            },
            {
                title: 'UI/UX',
            },
            {
                title: 'Cyber Security',
            },
            {
                title: 'DevOps',
            },
            {
                title: 'Flutter',
            },
        ],
    };

    const renderCategories_item = (item, i) => {
        return (
            <div className="categories-item" key={`categories-item_${i}`}>
                <input className='categories-checkbox' type="checkbox" id="updates" name={`categories-${i}`} value={false} />
                <label className='categories-checkbox_label p1' htmlFor={`categories-${i}`}>{item.title}</label>
            </div>
        );
    };

    return (
        <section className="categories-wrap">
            <div className="container container_padding categories">
                <div className="categories-head">
                    <h2 className="categories-title">Choose your tech stack to start the journey</h2>
                </div>
                <div className="categories-body">
                    <div className="categories-content">
                        {state.categories_items.map((item, i) => renderCategories_item(item, i))}
                    </div>
                    <PopupJoin name='Join' />
                </div>
            </div>
        </section>
    );
};

export default Categories;